import sortBy from 'lodash.sortby';
import ParserHelpers, { IStructureToPropsMapResult } from 'utils/parseHelpers';

import { parseSymptomsList } from 'components/SymptomsIllustrations/parsers';
import { parseBanner } from 'components/Banner/parsers';
import { parseNFButton } from 'components/common/NFButton/parsers';
import { parseExtendedFeaturedArticles } from 'components/ExtendedFeaturedArticles/parsers';

import { IUmbracoHomePageIncomeData, IUmbracoHomePageParsedData } from './models';

const parseUmbracoHomePageIncomeData = (
  param: IUmbracoHomePageIncomeData
): IUmbracoHomePageParsedData => {
  const data: IStructureToPropsMapResult = ParserHelpers.getStructureToPropsMap(
    param.umbracoHome.body
  );

  const featuredArticlesData = data['Featured Articles']?.properties;
  const whyNurofenData = data['Info Banner']?.properties;
  const featuredProductsData = data['Featured Products']?.properties;
  const videoSection = data['Video Section']?.properties;
  const simpleSection = data['Simple Section']?.properties.simpleSection;
  const extendedFeaturedArticles =
    data['Featured Articles From Symptoms & Pain Advice Sections']?.properties;
  const sortedProducts = sortBy(param.featuredProducts.nodes, (item) =>
    param.featuredProductsLinks.indexOf(item.link)
  );
  const painBanner = data['Pain Banner']?.properties;
  const painBannerExtendTitle = data['Pain Banner Extend Title']?.properties;
  const promotionHeroBanner = data['Promotion Hero Banner']?.properties;
  const painHomeBanner = data['Pain Home Banner']?.properties;

  const result: IUmbracoHomePageParsedData = {
    symptomsList: data['Symptoms List']
      ? parseSymptomsList(data['Symptoms List'].properties)
      : undefined,
    banner: data['Page Banner']
      ? parseBanner(data['Page Banner'].properties, { breadCrumbs: [] })
      : undefined,
    relatedArticles: featuredArticlesData
      ? {
          bgColor: featuredArticlesData.featuredArticlesBgColor,
          title: featuredArticlesData.featuredArticlesSectionTitle,
          subtitle: featuredArticlesData.featuredArticlesSectionSubtitle,
          btn: featuredArticlesData.featuredArticlesCTAButtonData?.length
            ? { ...parseNFButton(featuredArticlesData.featuredArticlesCTAButtonData[0].properties) }
            : undefined,
          articles: featuredArticlesData.featuredArticlesData,
          mode: featuredArticlesData.featuredArticlesSelectedArticleMode,
          articlesByTag: param.featuredArticlesByTag.nodes,
          carouselControls: param.carouselControls,
        }
      : undefined,
    whyNurofen: whyNurofenData
      ? {
          title: whyNurofenData.title,
          titleColor: whyNurofenData.infoBannerTitleTextColor,
          listDotColor: whyNurofenData.infoBannerBulletColor,
          content: whyNurofenData.description,
          contentBottom: whyNurofenData.descriptionBottom,
          mainImg: whyNurofenData.image,
          mainImgAlt: whyNurofenData.infoBannerImageAlt,
          brands: whyNurofenData.infographics,
          bgColor: whyNurofenData.infoBannerBgColor,
          hideImageOnMobile: whyNurofenData.infoBannerHideImageOnMobile === '1',
        }
      : undefined,
    featuredProducts: featuredProductsData
      ? {
          title: featuredProductsData.featuredProductsSectionTitle || null,
          subtitle: featuredProductsData.featuredProductsSectionSubtitle || null,
          sectionBtn: featuredProductsData.featuredProductsCTASectionButtonData?.length
            ? {
                ...parseNFButton(
                  featuredProductsData.featuredProductsCTASectionButtonData[0].properties
                ),
              }
            : undefined,
          bgColor: featuredProductsData.featuredProductsBgColor,
          enableProductsCTA: featuredProductsData.featuredProductsEnableProductsCTA,
          enableProductsSecondaryText:
            featuredProductsData.featuredProductsEnableProductsSecondaryText,
          responsiveCarouselStructure:
            featuredProductsData.featuredProductsResponsiveCarouselStructure,
          products: sortedProducts,
          cardBtnData: featuredProductsData.featuredProductsCTACardButtonData?.length
            ? {
                text: featuredProductsData.featuredProductsCTACardButtonData[0].properties.text,
                ariaLabel:
                  featuredProductsData.featuredProductsCTACardButtonData[0].properties.ariaLabel,
                btnStyles:
                  featuredProductsData.featuredProductsCTACardButtonData[0].properties.btnStyles,
                icon: featuredProductsData.featuredProductsCTACardButtonIcon,
              }
            : undefined,
          carouselControls: param.carouselControls,
        }
      : undefined,
    videoSection: videoSection
      ? {
          section: videoSection.videoSection,
          videoBlock: videoSection.videos.map(({ properties }) => ({
            properties: {
              btnPlay: properties.btnPlay,
              previewImg: properties.previewImg[0].properties,
              videoLink: properties.videoLink,
              videoTitle: properties.videoTitle,
              videoDescription: properties.videoDescription,
              isVideoRounded: properties.isVideoRounded,
            },
          })),
        }
      : undefined,
    simpleSection: simpleSection
      ? {
          sectionTitle: simpleSection[0].properties.sectionTitle,
          sectionSubtitle: simpleSection[0].properties.sectionSubtitle,
          sectionBgColor: simpleSection[0].properties.sectionBgColor,
        }
      : undefined,
    extendedFeaturedArticles: extendedFeaturedArticles
      ? parseExtendedFeaturedArticles({
          extendedFeaturedArticles,
          articlesByLink: [
            ...param?.extendedFeaturedArticlesPainAdviceByLink?.nodes,
            ...param?.extendedFeaturedArticlesSymptomsByLink?.nodes,
          ],
          articlesByTag: [
            ...param?.extendedFeaturedArticlesPainAdviceByTag?.nodes,
            ...param?.extendedFeaturedArticlesSymptomsByTag?.nodes,
          ],
          carouselControls: param.carouselControls,
        })
      : undefined,
    painBanner: painBanner
      ? {
          title: painBanner.title,
        }
      : undefined,
    painBannerExtendTitle: painBannerExtendTitle
      ? {
          title: painBannerExtendTitle.title,
          image: painBannerExtendTitle.image,
          imageMobile: painBannerExtendTitle.imageMobile,
          imageAlt: painBannerExtendTitle.imageAlt,
          header: painBannerExtendTitle.header,
          headerSmall: painBannerExtendTitle.headerSmall,
          buttonLink: painBannerExtendTitle.buttonLink?.length
            ? { ...parseNFButton(painBannerExtendTitle.buttonLink[0].properties) }
            : undefined,
          text: painBannerExtendTitle.text,
        }
      : undefined,
    promotionHeroBanner: promotionHeroBanner
      ? {
          ...promotionHeroBanner,
        }
      : undefined,
    painHomeBanner: painHomeBanner
    ? {
      ...painHomeBanner,
    } : undefined,
  };

  return result;
};

export default parseUmbracoHomePageIncomeData;
